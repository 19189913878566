import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { Attachment } from 'src/app/component/attachments/attachments.types';
import { ItemListReadConfirmDialogComponent } from 'src/app/component/elearning/item-list/item-list-read-confirm-dialog/item-list-read-confirm-dialog.component';
import {
  ItemListReadConfirmDialogData,
  ItemListReadConfirmDialogResult
} from 'src/app/component/elearning/item-list/item-list.types';
import { UserContentActionEnum } from 'src/app/core/content/content.types';
import { InfoService } from 'src/app/core/info/info.service';
import { Quests } from 'src/app/core/quest/quest.types';
import { isNothing } from 'src/app/core/utils';
import { CatalogBookingHelper } from '../../../core/catalog/catalog-booking.helper';
import { CatalogService } from '../../../core/catalog/catalog.service';
import { ContentService } from '../../../core/content/content.service';
import { Core, ImageableContentReference } from '../../../core/core.types';
import { CourseTypeHelper } from '../../../core/course-type-helper';
import { DateHelper } from '../../../core/date.helper';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { DisplayStatus } from '../../../core/display-status.enum';
import { destroySubscriptions, onceUntilDestroyed, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { StyleSettings } from '../../admin/account-design/account-design.types';


@Component({
  selector: 'rag-content',
  templateUrl: './content.component.html',
  styleUrls: [ './content.component.scss' ],
})
export class ContentComponent implements OnDestroy, OnInit {

  attachments: Array<Attachment>;
  account: ImageableContentReference[];
  content: ImageableContentReference;
  contentId: number;
  highlightItemId: number;
  styleSettings$: Observable<StyleSettings>;
  isNothing = isNothing;
  protected quest2Content: Quests.Quest2ContentResponse;
  private hasCheckedCompletion_ = false;

  constructor(
    private accountDesignService: AccountDesignService,
    private catalogBookingHelper: CatalogBookingHelper,
    private catalogService: CatalogService,
    private contentService: ContentService,
    private infoService: InfoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.styleSettings$ = this.accountDesignService.getStyleSettings();
    this.highlightItemId = parseInt(this.route.snapshot.queryParamMap.get('highlight'), 10) || null;
  }

  ngOnInit() {

    // fetch data on initial page load / from resolver
    this.route.data
      .pipe(tap(data => {
        this.quest2Content = {
          quests: data.content.quests,
          feedbacks: data.content.feedbacks,
          settings: data.content.settings
        };
        this.attachments = data.content.attachments;
        this.updateRouteData(data?.content?.content, null);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    // replace data if account is refreshed from some other place
    this.contentService.accountData$
      .pipe(map(account => this.updateRouteData(ContentService.getContentById(account, this.contentId,
        false, [ Core.DistributableType.lms_course, Core.DistributableType.lms_curriculum ]), account)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  isCancelBookingDisabled(): boolean {
    const displayStatus = DisplayStatusHelper.toDisplayStatus(this.content.displaystatus);
    return !(displayStatus === DisplayStatus.NOT_ATTEMPTED);
  }

  onCancelBooking(): void {

    const bookingUUID = this.content?.bookingUUID;
    if (!bookingUUID || !this.content?.booked) {
      return;
    }

    this.catalogService.getCatalogEntryForBooking(this.content.objType, this.contentId, bookingUUID)
      .pipe(switchMap(catalogEntry => this.catalogBookingHelper.cancel(catalogEntry)))
      .pipe(switchMap(() => this.router.navigate(['content-overview'], {
        queryParams: {
          reset: true
        }
      })))
      .pipe(onceUntilDestroyed(this))
      .subscribe();
  }

  onReadDocumentAcknowledge() {
    this.contentService.fetchCourseInfo(this.contentId)
      .pipe(switchMap(courseInfo =>
        this.infoService.showDialog<ItemListReadConfirmDialogComponent, ItemListReadConfirmDialogData, ItemListReadConfirmDialogResult>(ItemListReadConfirmDialogComponent, {
          content: courseInfo,
          hasSupervisor: this.content?.hasSupervisor,
        })
          .pipe(map(result => {
            if (result == null || !result.confirm) {
              // close button
              return;
            }

            const action = result.text != null ? UserContentActionEnum.NEED_CLARIFICATION : UserContentActionEnum.READ_ACKNOWLEDGE;

            this.contentService.handleUserContentAction({
              objId: this.content.id,
              objType: this.content.objType,
              action,
              text: result.text
            });
          }))
      ))
    .subscribe();
  }

  private updateRouteData(
    content: ImageableContentReference | null,
    account: ImageableContentReference[] | null,
  ): void {

    if ( account != null ) {
      this.account = account;
    }

    if ( content == null ) {
      // ignore?
      return;
    }

    this.content = content;
    this.contentId = content.id;

    if ( !this.hasCheckedCompletion_ && ContentService.shouldCheckCurriculumCompletion(content) ) {
      this.hasCheckedCompletion_ = true;

      this.contentService.checkCurriculumCompletion(content)
        // add some delay to allow any hypothetical async processes
        .pipe(debounceTime(2000))
        // reload data after triggering validation
        .pipe(switchMap(() => this.contentService.fetchAccountData(true)))
        .pipe(take(1))
        .subscribe();
    }

  }

  getSubtitle(): string | null {
    const eventDate = DateHelper.toMoment(this.content?.eventDate);
    if (eventDate == null || !CourseTypeHelper.isRecording(this.content)) {
      return null;
    }
    return $localize`:@@recording_date:Recording date` +  ': ' +
      DateHelper.format(eventDate, 'L');
  }
}
