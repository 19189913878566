import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ApiUrls } from "./api.urls";
import { Core } from "./core.types";
import { Fields } from "./fields.types";
import { ApiResponse, HttpRequestOptions } from "./global.types";

@Injectable({
  providedIn: 'root',
})
export class FieldsAdminService {

  constructor (
    private http: HttpClient
  ) {}

  getCustomFieldsById(objType: Core.DistributableType, objId: number): Observable<Fields.ContentFields> {
    const url = ApiUrls.getKey('CustomFieldsById')
      .replace('{objType}', objType)
      .replace('{objId}', String(objId));
    return this.http.get<ApiResponse<Fields.ContentFields>>(url)
      .pipe(map(response => response.data));
  }

  getCustomFieldsByUUID(objType: Core.DistributableType, objId: string): Observable<Fields.ContentFields> {
    const url = ApiUrls.getKey('CustomFieldsByUUID')
      .replace('{objType}', objType)
      .replace('{objId}', objId);
    return this.http.get<ApiResponse<Fields.ContentFields>>(url)
      .pipe(map(response => response.data));
  }

  saveContentFieldsForContent(objType: Core.DistributableType, objId: number, fields: Fields.Field2Content[]): Observable<Array<Fields.Field2Content>> {
    const url = ApiUrls.getKey('AdminContentFields')
      .replace('{objType}', objType)
      .replace('{objId}', String(objId));
    return this.http.post<ApiResponse<Array<Fields.Field2Content>>>(url, fields, HttpRequestOptions)
      .pipe(map(response => response.field2Contents));
  }

}