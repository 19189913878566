import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from '../../../../component/dialog-header/dialog-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'rag-self-register-already-taken-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogHeaderComponent,
    MatButtonModule,
    MatDialogModule,
    RouterLink,
  ],
  templateUrl: './self-register-already-taken-dialog.component.html',
  styleUrls: ['./self-register-already-taken-dialog.component.scss']
})
export class SelfRegisterAlreadyTakenDialogComponent {

}
