import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TableHeaderFilterDefaultModule
} from '../table/table-header-filter-default/table-header-filter-default.module';
import { ColumnFilterV2 } from '../../core/column-settings/column-filter.types';
import { TableControllerTypes } from '../table/table-controller/table-controller.types';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Observable, of } from 'rxjs';
import { CachedSubject } from 'src/app/core/cached-subject';
import { TableFilterMenuComponent } from '../table-filter-menu/table-filter-menu.component';
import { TableHelper } from '../table/table-helper';
import { ContentFilterHelper } from '../content-filter/content-filter.helper';
import { parseFilterOperator } from 'src/app/core/column-settings/column-filter.types';
import { StorageHelper } from '../../core/storage/storage.helper';
import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AnyObject } from '../../core/core.types';
import { destroySubscriptions, onceUntilDestroyed, takeUntilDestroyed } from '../../core/reactive/until-destroyed';
import { TableColumnMenuService } from '../table/table-column-menu/table-column-menu.service';
import { PrincipalService } from '../../core/principal/principal.service';

/**
 * ATTENTION <br>
 * If TableFilterComponent is used you have to extend TableControllerComponent.<br>
 * Otherwise, you have to trigger @see TableColumnMenuService.nextDataSourceFilter manually after filter has changed.<br>
 * Moreover, if tableId is null, nothing will be saved in localForage.
 */
@Component({
  selector: 'rag-table-filter',
  standalone: true,
  imports: [
    CommonModule,
    TableHeaderFilterDefaultModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    TableFilterMenuComponent,
  ],
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent<T>
  implements OnInit, OnDestroy, OnChanges {

  @Input() menuData: TableControllerTypes.ColumnMenuData<T>;
  @Input() tableId: string;

  @Output() readonly filterChange: Observable<{
    filter: ColumnFilterV2<string, T>,
    column: TableControllerTypes.ColumnMenuItem<T>,
  }>;
  @Output() readonly selectedFilter$: Observable<TableControllerTypes.ColumnMenuItem[]>;

  private _filterChange = new EventEmitter();
  private _selectedFilter$ =
    new CachedSubject<TableControllerTypes.ColumnMenuItem[]>(null);

  constructor(
    private principalService: PrincipalService,
    private tableColumnMenuService: TableColumnMenuService,
  ) {
    this.selectedFilter$ = this._selectedFilter$.asObservable();
    this.filterChange = this._filterChange.asObservable()
      .pipe(debounceTime(0));
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.getFilterItems(this.menuData)
      .forEach(menuItem => {
        const filterActive = ContentFilterHelper.isFilterActive(menuItem.options.filter);
        this.onSelectionChanged({ filter: menuItem, active: filterActive.active });
      });
    this.initializeFilter();
  }

  ngOnInit(): void {
    this.tableColumnMenuService.filterChanged$
      .pipe(map(() => {
        this.updateStorage(this.menuData, this.tableIdWithUser);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  protected getMenuDataAsQueryParams(menuData: TableControllerTypes.ColumnMenuData<T> | null): AnyObject<string> | null {
    if (menuData?.menuItems == null) {
      return null;
    }

    const activeFilterItem = this.getFilterItems(menuData)
      .filter(menuItem => {
        // ensure we have a valid filterStateAttribute
        menuItem.options.filterStateAttribute ??= (menuItem.id ?? menuItem.options.filter.identifier);
        // include any active filter, ignoring if it is changed or not
        return ContentFilterHelper.isFilterActive(menuItem.options.filter).active;
      });
    if (activeFilterItem.length === 0) {
      return null;
    }

    const filters = activeFilterItem
      .map(o => o.options);
    return ContentFilterHelper.asQueryParams(filters);
  }

  protected initializeFilter(): void {
    of(this.tableIdWithUser)
      .pipe(switchMap(tableId => {
        if (tableId != null) {
          return StorageHelper.get<AnyObject<string>>(tableId)
            .pipe(take(1))
            .pipe(filter(o => o != null));
        } else {
          return of(this.getMenuDataAsQueryParams(this.menuData));
        }
      }))
      .pipe(tap(o => {
        Object.keys(o).forEach(filterStateAttribute => {
          const filter = this.getFilterItems(this.menuData)
            .find(filter => {
              if (filter.options.filterStateAttribute != null) {
                return filter.options.filterStateAttribute === filterStateAttribute;
              } else if (filter.options.filter.identifier != null) {
                return filter.options.filter.identifier === filterStateAttribute;
              }
              return filter.id === filterStateAttribute;
            });
          if (!filter) {
            return;
          }
          const value = o[filterStateAttribute];
          if ((value != null)) {
            filter.hasFilter = true;
            const actionPos = value.lastIndexOf('$');
            if (actionPos > 0) {
              filter.options.filter.action = parseFilterOperator(value.substring(actionPos));
              filter.options.filter.value = value.substring(0, actionPos);
            } else {
              filter.options.filter.value = value;
            }
            this.onSelectionChanged({ filter: filter, active: true });
          }
        });
      }))
      .pipe(onceUntilDestroyed(this))
      .subscribe();
  }

  protected onFilterChange(filter: ColumnFilterV2, column: TableControllerTypes.ColumnMenuItem): void {
    this._filterChange.next({ filter: filter, column: column });
  }

  private updateStorage(menuData: TableControllerTypes.ColumnMenuData<T> | null, tableId: string | null): void {
    if (tableId == null) {
      return;
    }

    const queryParams = this.getMenuDataAsQueryParams(menuData);
    if (queryParams == null) {
      // remove saved default state
      StorageHelper.remove(this.tableIdWithUser)
        .pipe(take(1))
        .subscribe();
      return;
    }

    StorageHelper.save(this.tableIdWithUser, queryParams)
      .pipe(take(1))
      .subscribe();
  }

  protected onSelectionChanged(
    event: { filter: TableControllerTypes.ColumnMenuItem<T>, active: boolean },
  ): void {
    const selectedFilters = this._selectedFilter$.value ?? [];

    const filterId = event.filter?.id;
    const oldFilterIndex = selectedFilters.findIndex(o => o.id === filterId);
    const oldFilter = (oldFilterIndex != -1) ? selectedFilters[oldFilterIndex] : null;

    if (event.active) {

      if (oldFilter == null) {
        selectedFilters.push(event.filter);
      }else if (oldFilter !== event.filter) {
        // remove identical filter that is not the same filter object
        selectedFilters.splice(oldFilterIndex, 1);
        selectedFilters.push(event.filter);
      }

      this._selectedFilter$.next(selectedFilters);

    } else {
      if (oldFilter != null) {
        selectedFilters.splice(oldFilterIndex, 1);
      }
      this._selectedFilter$.next(selectedFilters);
      TableHelper.resetMenuData(event.filter, event.filter.options.filter.defaultValue);
      this.onFilterChange(event.filter.options.filter, event.filter);
    }
  }

  protected getFilterItems(menuData: TableControllerTypes.ColumnMenuData): TableControllerTypes.ColumnMenuItem[] {
    return Object.values(menuData.menuItems)
      .filter(o => !o.hidden)
      .sort((a, b) => a.orderIndex - b.orderIndex);
  }

  protected get tableIdWithUser(): string | null {
    if (!this.tableId) {
      return null;
    }

    return `${this.tableId}-${this.principalService.userId}`;
  }

  protected removeAllFilter(): void {
    this._selectedFilter$.value
      .forEach(column => {
        TableHelper.resetMenuData(column, column.options.filter.defaultValue);
        if (column.options.filter.defaultValue == null || column.options.filter.defaultValue === '') {
          this.onSelectionChanged({ filter: column, active: false });
        } else {
          this.onFilterChange(column.options.filter, column);
        }

      });
    if (this.tableId != null) {
      StorageHelper.remove(this.tableId)
        .pipe(take(1))
        .subscribe();
    }
  }

}
