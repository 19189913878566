import { Injectable } from '@angular/core';
import { InfoService } from '../../../../core/info/info.service';
import { Observable, of, switchMap } from 'rxjs';
import { SelfRegisterAlreadyTakenDialogComponent } from './self-register-already-taken-dialog.component';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SelfRegisterAlreadyTakenDialogService {

  constructor(
    private infoService: InfoService,
    private router: Router,
  ) {
  }

  public showDialog(): Observable<void> {
    return this.infoService
      .showDialog<SelfRegisterAlreadyTakenDialogComponent, null, string>(SelfRegisterAlreadyTakenDialogComponent, null)
      .pipe(take(1))
      .pipe(switchMap(url => !url ? of(void (0)) : this.router.navigateByUrl(url)))
      .pipe(map(_ => void (0)));
  }

}
