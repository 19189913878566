<rag-dialog-header>
  <span i18n="@@sys-hint-error">Hint</span>
</rag-dialog-header>

<div mat-dialog-content>
  <span i18n="@@self_register_already_taken_1">For this email address (username), an account already exists.</span>
  <ul>
    <li i18n="@@self_register_already_taken_2">
      If this account belongs to you, please log in using the username (email
      address) and the associated password.
    </li>
    <li i18n="@@self_register_already_taken_3">
      If you have forgotten your password, use the "Forgot Password" function
      to receive a new password at your registered email address.
    </li>
  </ul>
</div>

<div mat-dialog-actions>
  <a
    class="rag-stroked-button" mat-stroked-button
    routerLink="/forgot-password"
    [mat-dialog-close]="'/forgot-password'"
  >
    <span i18n="@@login_page_forgot_password_button">Forgot password?</span>
  </a>
  <a
    class="rag-stroked-button" mat-stroked-button
    routerLink="/login/redirect"
    [mat-dialog-close]="'/login/redirect'"
  >
    <span i18n="@@global_login">Login</span>
  </a>
</div>
