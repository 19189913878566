import {
  NavigationData,
  NavItemIcon,
  NavItemMenu,
  NavItemMenuIcon,
  NavItemSpecial,
  NavItemType,
} from './navigation.types';


export const DEFAULT_NAVIGATION_RAG_DIALOG_GUEST: NavigationData = {
  isGuest: true,
  showContentSearch: false,
  top: [
    {
      type: NavItemType.menuIcon,
      special: NavItemSpecial.language,
    } as NavItemMenuIcon,
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@global_login:Login`,
      icon: 'login',
      special: NavItemSpecial.login,
      checkPermissions: 'isGuest',
    } as NavItemIcon,
  ],
  main: [],
};

export const DEFAULT_NAVIGATION_RAG_DIALOG_USER: NavigationData = {
  isGuest: false,
  showContentSearch: false,
  top: [
    {
      type: NavItemType.linkIcon,
      title: $localize`:@@header_top_messages:Messages`,
      icon: 'email',
      checkPermissions: 'navUserMessages',
      routerLink: '/mailbox/inbox',
      additionalRoutes: [ '/mailbox' ],
      special: NavItemSpecial.mailbox,
    },
    {
      type: NavItemType.buttonIcon,
      title: $localize`:@@logout_button:Logout`,
      icon: 'logout',
      special: NavItemSpecial.logout,
    } as NavItemIcon,
  ],
  main: [

    {
      type: NavItemType.link,
      title: $localize`:@@create_new_contribution:Create new contribution`,
      routerLink: '/qa/new',
      checkPermissions: 'navNewQA',
      icon: 'assets/icons/svg/icon_nav_feedback.svg',
    } as NavItemIcon,

    {
      type: NavItemType.link,
      title: $localize`:@@read_contributions:Read contributions`,
      routerLink: '/qa',
      routerLinkActiveOptions: {queryParams: 'ignored', paths: 'exact', matrixParams: 'exact', fragment: 'exact'},
      checkPermissions: 'navQA',
      icon: 'assets/icons/svg/icon_nav_qa.svg',
    } as NavItemIcon,

    {
      type: NavItemType.link,
      title: $localize`:@@global_catalog:Catalog`,
      routerLink: '/catalog',
      checkPermissions: 'navCatalog',
      icon: 'assets/icons/svg/icon_nav_catalog.svg',
    } as NavItemIcon,

    {
      type: NavItemType.menu,
      title: $localize`:@@header_nav_administrator:Administration`,
      icon: 'assets/icons/svg/icon_nav_admin.svg',
      children: [

        {
          type: NavItemType.menu,
          title: $localize`:@@admin_offline_content_header:Events`,
          checkPermissions: 'navAdminOfflineContent',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_list_view:List view`,
              routerLink: '/admin/offline/content',
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_calendar_view:Calendar View`,
              routerLink: '/admin/offline/content/cal/{date}/month',
              special: NavItemSpecial.adminOfflineCalendar,
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_venues:Venues`,
              routerLink: '/admin/offline/location',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_trainer:Trainer`,
              routerLink: '/admin/offline/trainer',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_provider:Provider`,
              routerLink: '/admin/offline/provider',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_admin_offline_notification_templates:Notification templates`,
              routerLink: '/admin/offline/notifications',
              checkPermissions: 'navAdminOfflineNotification',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@categories:Categories`,
          routerLink: '/admin/categories',
          checkPermissions: 'navAdminCategories',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_catalogs:Catalogs`,
          routerLink: '/admin/catalog',
          checkPermissions: 'navAdminCatalog',
        },
        {
          type: NavItemType.link,
          title: $localize`:@@admin_lu_header:Learning content`,
          routerLink: '/admin/courses',
          checkPermissions: 'navAdminCourse',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@ctrl_single_user_details_nav_curricula:Curricula`,
          checkPermissions: 'navAdminCurriculum',
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_list_view:List view`,
              routerLink: '/admin/curriculum',
              queryParams: { type: '' },
              routerLinkActiveOptions: {exact: true},
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_cockpit_column_type_title:Online Agenda`,
              routerLink: '/admin/curriculum',
              queryParams: { type: 'a294578e-ab2f-4576-a106-0177600b67ad$eq' },
              routerLinkActiveOptions: {exact: true},
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_learner_cockpit:Learner Cockpit`,
              routerLink: '/admin/curriculum',
              queryParams: { type: '174b90c1-5781-4c1e-a5dd-989a3618af4e$eq' },
              routerLinkActiveOptions: {exact: true},
            }
          ]
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@admin_user_administration:User Administration`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_users:Users`,
              routerLink: '/admin/user',
              checkPermissions: 'navAdminUsers',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_user_imports:User imports`,
              routerLink: '/admin/user-import',
              checkPermissions: 'navAdminUsersCSV',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_groups:User groups`,
              routerLink: '/admin/user-groups',
              checkPermissions: 'navAdminUserGroups',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_target_groups:Target groups`,
              routerLink: '/admin/target-groups',
              checkPermissions: 'navAdminTargetGroups',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_self_registration:Self registration`,
              routerLink: '/admin/self-registration',
              checkPermissions: 'navAdminSelfRegistration',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@limited_user_administration:Limited user administration`,
              routerLink: '/admin/reduced-attributes',
              checkPermissions: 'navAdminReducedUserAdministration',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_data_fields:User data fields`,
              routerLink: '/admin/user-attribute',
              checkPermissions: 'navAdminUserAttribute',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_user_learner_state:Learner state import`,
              routerLink: '/admin/learner-state',
              checkPermissions: 'navAdminLearnerStateImport',
              hideForIlearn: 'apply'
            },
          ],
        },

        {
          type: NavItemType.link,
          title: $localize`:@@admin_news:News`,
          routerLink: '/admin/news',
          checkPermissions: 'navAdminNews',
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@admin_content_content_notifications:Notifications`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_top_messages:Messages`,
              routerLink: '/admin/notifications/message',
              checkPermissions: 'navAdminNotifications',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@general_signatures:Signatures`,
              routerLink: '/admin/notifications/signatures',
              checkPermissions: 'navAdminSignatures',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@global_url_call:URL-Call`,
              routerLink: '/admin/notifications/urlCall',
              checkPermissions: 'navAdminNotifications',
            },
          ],
        },
        {
          type: NavItemType.link,
          title: $localize`:@@global_contribution_moderation:Contribution moderation`,
          routerLink: '/admin/qa',
          checkPermissions: 'navAdminQA',
        },
        {
          type: NavItemType.menu,
          title: $localize`:@@global_integration:Integration`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_login:Virtual platforms`,
              routerLink: '/admin/ext/login',
              checkPermissions: 'navAdminExternalLogin',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_oidc:OpenID Connect`,
              routerLink: '/admin/ext/oauth2',
              checkPermissions: 'navAdminExternalOAuth2',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_ext_dsb_gateway_settings:DSB-Gateway Settings`,
              routerLink: '/admin/ext/dsbGateway',
              checkPermissions: 'navAdminExternalDSBGateway',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@admin_script_account_script:Account-wide JavaScript`,
              routerLink: '/admin/script',
              checkPermissions: 'navAdminJavascript',
            },
          ],
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@header_nav_development-goals:Development goals`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_development_goals_distribution:Distribution`,
              routerLink: '/admin/development-goals/licence-group/assignment',
              checkPermissions: 'navAdminDistributionLimited',
            },
          ],
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@admin_permissions:Permissions`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@global_roles:Roles`,
              routerLink: '/admin/roles',
              checkPermissions: 'navAdminRoles',
            },
          ],
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@account_design:Account design`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_general:General`,
              routerLink: '/admin/account-design/general',
              checkPermissions: 'navAdminAccountDesign',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_login:Login`,
              routerLink: '/admin/account-design/login',
              checkPermissions: 'navAdminAccountDesignLogin',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_footer:Footer`,
              routerLink: '/admin/account-design/footer',
              checkPermissions: 'navAdminAccountDesign',
            },
          ],
        },

        {
          type: NavItemType.menu,
          title: $localize`:@@global_configurations:Configurations`,
          children: [
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_report_generator:Report generator`,
              routerLink: '/admin/configuration/report-gen-admin',
              checkPermissions: 'navAdminReportGenerator',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@header_nav_account_design_widgets:Widgets`,
              routerLink: '/admin/configuration/widgets',
              checkPermissions: 'navAdminAccountDesign',
            },
            {
              type: NavItemType.link,
              title: $localize`:@@landing_page:Landing page`,
              routerLink: '/admin/configuration/start-page',
              checkPermissions: 'navAdminAccountDesign',
            },
          ],
        },

      ],
    } as NavItemMenu,
  ],
};
