import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NgControl,
  UntypedFormControl,
  ValidationErrors
} from '@angular/forms';


export class InputHelper {

  /**
   * @return true, if any control in the form is invalid
   */
  public static touchIfInvalid(
    form: FormGroup | null,
  ): boolean {
    if (form == null || form.valid) {
      return false;
    }

    let touched = false;
    for (const control of Object.values(form?.controls ?? {})) {
      if (control.valid) {
        continue;
      }
      touched = true;
      control.untouched && control.markAsTouched();
    }
    return touched;
  }

  public static clearStatus(
    formControl: AbstractControl,
  ): void {
    formControl.markAsUntouched();
    formControl.markAsPristine();
  }

  public static getError(
    formControl: AbstractControl,
  ): string | null {

    if ( (formControl == null) || formControl.untouched ) {
      // ignore errors until the field has been touched
      return null;
    }

    if ( formControl.hasError('required') ) {
      return $localize`:@@validation_required:This field is required`;
    }

    const errors = formControl.errors;
    const keys = Object.keys(errors ?? {});
    if ( keys.length === 0 ) {
      return null;
    }

    for (const key of keys) {
      const errorMessage = errors[key];
      if ( typeof errorMessage === 'string' ) {
        return errorMessage;
      }
    }
    return null;
  }

  public static setErrors(
    formControl: AbstractControl,
    errors: ValidationErrors | null,
  ): void {
    if (formControl == null) {
      return;
    }

    if (errors == null) {
      formControl.setErrors(null);
      return;
    }

    for (const key of Object.keys(errors)) {
      // remove all null / false / '' values ("unset" errors)
      if (!errors[key]) {
        delete errors[key];
      }
    }
    const hasError = Object.keys(errors).length > 0;
    formControl.setErrors(hasError ? errors : null);
  }

  public static setInitialValue<T>(
    formControl: AbstractControl | null,
    value: T,
  ): void {
    if (formControl == null) {
      return;
    }
    formControl.setValue(value);
    InputHelper.clearStatus(formControl);
  }

  public static setValue<T>(
    formControl: AbstractControl | null,
    value: T,
    /**
     * defaults to: { touched: true, dirty: true, emitEvent: true }. Properties may be skipped (will use defaults).
     */
    state: { touched?: boolean, dirty?: boolean, emitEvent?: boolean } = { touched: true, dirty: true, emitEvent: true }
  ): void {
    if (formControl == null) {
      return;
    }

    const changed = formControl.value !== value;
    if (!changed) {
      return;
    }

    const emitEvent = state?.emitEvent !== false;
    formControl.setValue(value, { emitEvent });
    if (state?.dirty !== false) {
      formControl.markAsDirty({ onlySelf: !emitEvent });
    }
    if (state?.touched !== false) {
      formControl.markAsTouched({ onlySelf: !emitEvent });
    }
  }

  public static toggleEnabled(
    formControl: AbstractControl | null,
    enabled: boolean,
    emitEvent: boolean = true,
  ): void {

    if (formControl == null) {
      // avoid NPEs
      return;
    }

    if ( enabled && !formControl.enabled ) {
      // enable only if disabled
      formControl.enable({ emitEvent });

    } else if ( formControl.enabled && !enabled ) {
      // disable only if enabled
      formControl.disable({ emitEvent });
    }
  }

  static hasAnyLoading(form: AbstractControl | null): boolean {
    if (form == null) return false;
    const firstLoadingControl = [form, ...Object.values((<FormGroup>form)?.controls ?? {})]
      .find(o => o?.errors?.['loading'] === true);
    return firstLoadingControl != null;
  }

}

export namespace InputTypes {

  export class Util {

    static initializeValueState<T>(valueState: ValueState<T>): void {
      if ( valueState == null ) {
        return;
      }

      if ( valueState.originalValue === undefined ) {
        valueState.originalValue = valueState.currentValue;
      }
      if ( valueState.originalIndeterminate === undefined ) {
        valueState.originalIndeterminate = valueState.indeterminate;
      }
    }

    static resetValue<T>(valueState: ValueState<T>): void {
      valueState.currentValue = valueState.originalValue;
      valueState.indeterminate = valueState.originalIndeterminate;
      valueState.changed = false;
    }

    static updateFormControl<T>(formControl: UntypedFormControl, valueState: ValueState<T>): void {
      if ( (formControl == null) || (valueState == null) ) {
        return;
      }

      formControl.setValue(valueState.currentValue);
      if ( valueState.changed ) {
        formControl.markAsDirty();
      } else {
        formControl.markAsPristine();
      }
    }

    static updateValueStateChange<T>(valueState: ValueState<T>, value: T, indeterminate?: boolean): void {
      if ( valueState == null ) {
        return;
      }

      // apply currentValue
      if ( valueState.originalValue === undefined ) {
        valueState.originalValue = valueState.currentValue ?? value;
      }
      valueState.currentValue = value;

      if ( indeterminate != null ) {
        // apply indeterminate
        if ( valueState.originalIndeterminate == null ) {
          valueState.originalIndeterminate = valueState.indeterminate ?? indeterminate;
        }
        valueState.indeterminate = indeterminate;
      }

      // calculate changed state
      valueState.changed =
        // compare value if original is defined
        ((valueState.originalValue !== undefined) &&
          (valueState.currentValue !== valueState.originalValue)) ||
        // compare indeterminate state if original is defined
        ((valueState.originalIndeterminate !== undefined) &&
          (valueState.indeterminate !== valueState.originalIndeterminate));
    }

  }

  export interface ValueState<T> {
    changed?: boolean;
    currentValue?: T;
    disabled?: boolean;
    indeterminate?: boolean;
    originalIndeterminate?: boolean;
    originalValue?: T;
  }

  declare type fnOnChange = <T>(checked: T) => any;
  declare type fnOnTouched = () => any;

  export abstract class AbstractControlValueAccessor<T>
    implements ControlValueAccessor {

    onChange: fnOnChange;
    onTouched: fnOnTouched;
    private _ngControl: NgControl;
    private _valueState: InputTypes.ValueState<T>;

    protected constructor() {
    }

    get formControl(): UntypedFormControl {
      return this._ngControl?.control as UntypedFormControl;
    }

    get isDirty(): boolean {
      return this._valueState?.changed === true;
    }

    set value(value: T) {
      this.writeValue(value);
    }

    getValueState(): InputTypes.ValueState<T> {
      return this._valueState;
    }

    registerOnChange(fn: fnOnChange): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: fnOnTouched): void {
      this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      if ( this._valueState == null ) {
        return;
      }

      this._valueState.disabled = isDisabled;
    }

    setNgControl(value: NgControl): void {
      if ( value == null ) {
        return;
      }

      this._ngControl = value;
      this._ngControl.valueAccessor = this;
    }

    /**
     * Add a setter for valueState and call this method.
     * <pre>
     * | @Input() set valueState(value: InputTypes.ValueState<boolean>) {
     * |   this.setValueState(value);
     * | }
     * </pre>
     */
    setValueState(value: InputTypes.ValueState<T>) {
      this._valueState = value;
    }

    writeValue(value: T): void {
      const valueState = this._valueState;
      Util.updateValueStateChange(valueState, value);
      Util.updateFormControl(this.formControl, valueState);
    }

  }

}
