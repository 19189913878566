import { EventEmitter, OnDestroy } from '@angular/core';
import { EMPTY, MonoTypeOperatorFunction, Observable, pipe, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


const INSTANCES = new Map<OnDestroy, EventEmitter<void>>();

const getTakeUntil = (reference: OnDestroy): EventEmitter<void> => {
  let takeUntil$: EventEmitter<void> = INSTANCES.get(reference);

  if ( takeUntil$ == null || takeUntil$.closed ) {
    // replace missing or closed event emitters
    takeUntil$ = new EventEmitter<void>(false);
    INSTANCES.set(reference, takeUntil$);
  }

  return takeUntil$;
};


/**
 * Complete any subscriptions that are registered to the reference (from calls to {@link takeUntilDestroyed}).
 */
export const destroySubscriptions = (reference: OnDestroy): void => {
  const takeUntil$: EventEmitter<void> = INSTANCES.get(reference);
  if ( takeUntil$?.closed !== false ) {
    // this emitter is closed already, or does not exist at all
    return;
  }

  // remove emitter immediately to ensure we don't register this in some fictional race-condition
  INSTANCES.delete(reference);

  // trigger the destroy event, and close the emitter
  takeUntil$.next();
  takeUntil$.complete();
};

/**
 * Remember to clean up the subscriptions by calling {@link destroySubscriptions} in {@link OnDestroy.ngOnDestroy}.
 *
 * @see https://dev.to/re4388/use-rxjs-takeuntil-to-unsubscribe-1ffj
 * @see https://indepth.dev/posts/1421/rxjs-custom-operators
 */
export const takeUntilDestroyed = <T>(reference: OnDestroy): MonoTypeOperatorFunction<T> => pipe(takeUntil<T>(getTakeUntil(reference)));

export const onceUntilDestroyed = <T>(reference: OnDestroy): MonoTypeOperatorFunction<T> => pipe(take(1), takeUntil<T>(getTakeUntil(reference)));

/**
 * @deprecated Simply use {@link takeUntilDestroyed} directly.
 */
export const subscribeUntilDestroyed = <T>(observable: Observable<T>, reference: OnDestroy): Subscription => {
  if ( observable == null ) {
    // if the pipe is undefined, subscribe to the EMPTY-pipe which completes immediately
    return EMPTY.subscribe();
  }

  return observable
    .pipe(takeUntilDestroyed<T>(reference))
    .subscribe();
};
