<div [formGroup]="form">

  <mat-form-field appearance="outline">

    <mat-label>
      <mat-icon
        *ngIf="field.setByInterface === true"
        class="special-required"
        svgIcon="elevator"
      ></mat-icon>
      {{field.label | localeText}}
    </mat-label>

    <input
      matInput
      type="text"
      [formControlName]="field.fieldId"
      [name]="field.fieldId"
      [required]="field.required"
      inputmode="email"
      [pattern]="MAIL_PATTERN"
    />

    <mat-error
      *ngIf="getError() as error"
    >{{error}}</mat-error>

  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="shouldShowEMailRepeat()">

    <mat-label i18n="@@register_email_confirm">Confirm e-mail address</mat-label>

    <input
      matInput
      type="text"
      [formControlName]="field.fieldId + 'Repeat'"
      [name]="field.fieldId + 'Repeat'"
      inputmode="email"
      required
      autocomplete="off"
    />

    <mat-error
      *ngIf="getErrorRepeat() as error"
    >{{error}}</mat-error>

  </mat-form-field>

</div>
