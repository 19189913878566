import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { ApiUrls } from 'src/app/core/api.urls';
import { Core, Distributable } from 'src/app/core/core.types';
import { ApiResponse, HttpRequestOptions } from 'src/app/core/global.types';
import { PreloadService } from '../preload.service';
import { PrincipalService } from '../principal/principal.service';
import { Catalogs } from './catalog.types';
import { StorageHelper } from '../storage/storage.helper';
import { CachedSubject } from '../cached-subject';
import { Params } from '@angular/router';
import { InfoService } from '../info/info.service';
import { InfoType } from '../info/info.types';
import { Location } from '@angular/common';
import { AccountInterceptor } from '../interceptors/account.interceptor';
import { ProfileFieldTypes } from '../input/profile-field/profile-field.types';
import { CatalogUserFieldsComponent } from '../../component/catalog/catalog-user-fields/catalog-user-fields.component';
import { CatalogUserFieldsData } from '../../component/catalog/catalog-user-fields/catalog-user-fields.types';
import { Translation } from '../translation/translation.types';
import {
  CatalogDetailsCurriculumData
} from '../../route/user/catalog/catalog-details-curriculum/catalog-details-curriculum.types';
import { UrlHelper } from '../url.helper';
import { CatalogRedirectResponse } from '../../route/user/catalog/catalog.types';
import { RedirectHelper } from '../redirect.helper';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  bookingState$: Observable<Catalogs.BookingState>;
  bookedIds: number[];
  catalogUUID: string | null = null;

  private _bookingInProgress$ = new CachedSubject<Catalogs.BookingState>(null);
  private _catalogTitle: Translation | null;
  private _catalogUrl = '/catalog';

  constructor(
    private http: HttpClient,
    private preloadService: PreloadService,
    private principalService: PrincipalService,
    private infoService: InfoService,
    private location: Location,
  ) {
    this.bookingState$ = this._bookingInProgress$.withoutEmptyValues();
    this.bookedIds = [];
  }

  get catalogTitle(): Translation | null {
    return this._catalogTitle;
  }

  get catalogUrl(): string {
    return this._catalogUrl;
  }

  list(
    catalogUUID: string | null,
  ): Observable<Catalogs.Catalog> {

    const url = this.addCatalogUUID(ApiUrls.getKey('CatalogList'), catalogUUID);

    return this.http.get<ApiResponse<Catalogs.Catalog>>(url)
      .pipe(map(response => {
        const catalogs = response.catalog;

        if ( catalogUUID ) {
          catalogUUID = catalogUUID.toLowerCase();
          const catalogEntry = catalogs.catalogs
            ?.find(entry => entry.uuid?.toLowerCase() === catalogUUID);
          this.catalogUUID = catalogEntry?.uuid;
          this._catalogTitle = catalogEntry?.title;
        }

        return catalogs;
      }))

      // errors are replaced with an empty catalog object
      .pipe(catchError(e => {
        if ( e.errors?.[0]?.errorCode === 'ERR_CAT_004' ) {
          // access denied -> pass error on to resolver to redirect to home
          return throwError(e);
        }

        return of({
          contents: [],
          settings: {
            bookable: false,
            requestable: false,
            preBookable: false,
            published: true,
          },
        } as Catalogs.Catalog);
      }));
  }

  nextUpcomingEvents(count: number): Observable<Catalogs.Catalog> {
    const url = ApiUrls.getKey('CatalogList') + '/upcoming/' + String(count);
    return this.http.get<ApiResponse<Catalogs.Catalog>>(url).pipe(map(response => response.catalog));
  }

  book(objId: number, distType: Core.DistributableType, isReservation: boolean, bookingUUID?: string, moduleId?: number): Observable<Catalogs.CatalogBooking> {

      const url = ApiUrls.getKey('CatalogPublicBook_v2');
      const payload = {
        objId,
        objType: distType,
        bookingUUID,
        reservation: isReservation,
        moduleId
      };
      return this.http.post<ApiResponse<Catalogs.CatalogBooking>>(url, JSON.stringify(payload), HttpRequestOptions)
        .pipe(map(response => response.booking))
        .pipe(tap( booking => this.bookedIds.push( booking.id )));
  }

  bookEventSchedule(eventId: number, eventScheduleId: number, bookingUUID: string, isReservation: boolean ): Observable<Catalogs.CatalogBooking> {

    const url = ApiUrls.getKey('AccountOfflineContentBook_v2')
      .replace(/{offlineContentId}/gi, String(eventId))
      .replace(/{offlineEventId}/gi, String(eventScheduleId))
      .replace(/{bookingUUID}/gi, String(bookingUUID))
      .replace(/{isReservation}/gi, String(isReservation));

    return this.http.post<ApiResponse<Catalogs.CatalogBooking>>(url,  null)
      .pipe(map(response => response.booking))
      .pipe(tap( booking => this.bookedIds.push( booking.id )));
  }

  cancelBooking(catalogBookingId: number): Observable<Catalogs.CatalogBooking> {
    const url = ApiUrls.getKey('CatalogBook') + '/' + catalogBookingId ;
    return this.http.delete<ApiResponse<Catalogs.CatalogBooking>>(url).pipe(map(response => response.booking));
  }

  cancelBookingByEventSchedule(eventId: number, eventScheduleId: number): Observable<unknown> {
    const url = ApiUrls.getKey('AccountOfflineContentBook')
      .replace(/{offlineContentId}/gi, String(eventId))
      .replace(/{offlineEventId}/gi, String(eventScheduleId));

    return this.http.delete<ApiResponse<any>>(url);
  }

  checkRequiredUserFields(
    objectType: Core.DistributableType,
    objectId: number,
  ): Observable<void> {

    const url = ApiUrls.getKey('CatalogRequiredUserFields')
      .replace(/{objectType}/gi, objectType)
      .replace(/{objectId}/gi, String(objectId));
    return this.http.get<ApiResponse<ProfileFieldTypes.ProfileField[]>>(url)
      .pipe(map(response => response?.data ?? []))
      .pipe(catchError(this.handleError))
      .pipe(switchMap(this.showDialogRequiredUserFields))
      .pipe(map(_ => void (0)));
  }

  searchForEvents(
    contentId: number,
    contentType: Core.DistributableType,
    catalogUUID: string | null,
  ): Observable<Array<OfflineContent.EventCatalogView>> {

    let url = ApiUrls.getKey('CatalogEvents')
      .replace(/{objectType}/, contentType)
      .replace(/{objectId}/, String(contentId));
    url = this.addCatalogUUID(url, catalogUUID);

    return this.http.get<ApiResponse<Array<OfflineContent.EventCatalogView>>>(url)
      .pipe(map(response => response.offlineContents));
  }

  getCatalogEntryForBooking(
    contentType: Core.DistributableType,
    contentId: number,
    bookingUUID: string,
  ): Observable<Catalogs.CatalogEntry> {
    const url = ApiUrls.getKey('CatalogEntryByBooking')
      .replace(/{objectType}/, contentType)
      .replace(/{objectId}/, String(contentId))
      .replace(/{bookingUUID}/, bookingUUID);
    return this.http.get<ApiResponse<Catalogs.CatalogEntry>>(url)
      .pipe(map(response => response.catalogEntry));
  }

  getCatalogEntryForContent(
    contentType: Core.DistributableType,
    contentId: number,
    catalogUUID: string | null,
  ): Observable<Catalogs.CatalogEntry> {

    let url = ApiUrls.getKey('CatalogEntry')
      .replace(/{objectType}/, contentType)
      .replace(/{objectId}/, String(contentId));
    url = this.addCatalogUUID(url, catalogUUID);

    return this.http.get<ApiResponse<Catalogs.CatalogEntry>>(url)
      .pipe(map(response => response.catalogEntry));
  }

  bookingInProgress(obj: Distributable, withEvents: boolean) {
    this._bookingInProgress$.next({
      id: obj.id,
      objType: obj.objType,
      state: 'inprogress',
      withEvents
    });
  }

  completeBooking() {
    const obj = this._bookingInProgress$.getValue();
    obj.state = 'complete';
    this._bookingInProgress$.next(obj);
    return obj;
  }

  throwNotificationEvent(): Observable<ApiResponse<any>> {
    const url = ApiUrls.getKey('CatalogFinishBooking');
    return this.http.post<ApiResponse<any>>(url, {bookingIds: this.bookedIds}  )
      /*reset bookedIds after throwing noti event*/
      .pipe(tap( () => this.bookedIds.splice(0, this.bookedIds.length)));
  }

  abortBooking() {
    const obj = this._bookingInProgress$.getValue();
    if (obj != null) {
      obj.state = 'aborted';
      this._bookingInProgress$.next(obj);
    }

    StorageHelper.remove('catalogRedirect')
      .pipe(take(1))
      .subscribe();
  }

  failBooking() {
    const obj = this._bookingInProgress$.getValue();
    if (obj != null) {
      obj.state = 'failed';
      this._bookingInProgress$.next(obj);
    }
  }

  // clickedOnCatalog = true -> User has clicked on catalog <br>
  // clickedOnCatalog = false -> Catalog is default start page
  checkAccess(clickedOnCatalog: boolean, url: string | null): Observable<boolean | string> {
    return combineLatest([
      this.getCatalogState(),
      this.principalService.permissionStates$.pipe(take(1)),
      this.principalService.isLogged$.pipe(take(1)),
    ])
      .pipe(map(([ catalogState, permissionStates, isLogged ]) => {
        if (!catalogState.publicCatalog) {

          if (!isLogged) {

            // user is not logged in, without public catalog -> redirect to login
            if (RedirectHelper.isValidRedirect(url)) {
              return '/login/redirect?url=' + encodeURIComponent(url);
            } else {
              return '/login';
            }
          } else if (isLogged && !permissionStates.navCatalog) {

            // logged-in user who does not have permission to access the catalog
            return '/';
          }
        } else if (catalogState.empty) {

          if (!isLogged) {

            // catalog is empty -> redirect to home
            // this should be handled inside the train API to hide the public catalog
            if (clickedOnCatalog) {
              setTimeout(() => {
                this.infoService.showMessage($localize`:@@global_no_data:There are no entries to display.`,
                  {
                  infoType: InfoType.Warning,
                  durationInSeconds: 5,
                });
              }, 100);
            }
            return '/';
          } else {

            // show message that catalog is empty
            return true;
          }

        }

        // allow all other cases
        return true;
      }))
      .pipe(take(1));
  }

  getCatalogDetails(
    targetType: Core.DistributableType,
    targetId: number,
    catalogUUID: string | null,
    processHash: string | null,
  ): Observable<CatalogDetailsCurriculumData> {

    let url = ApiUrls.getKey('CatalogDetails')
      .replace(/{targetType}/gi, targetType)
      .replace(/{targetId}/gi, String(targetId));
    url = this.addCatalogUUID(url, catalogUUID);
    if ( processHash ) {
      const separator = url.includes('?') ? '&' : '?';
      url += `${separator}process=${processHash}`;
    }

    return this.http.get<CatalogDetailsCurriculumData>(url);
  }

  getCatalogState(): Observable<Catalogs.CatalogState> {
    return combineLatest([
      this.preloadService.getEnvJson(),
      this.preloadService.getCatalogState(),
    ])
      .pipe(map(([ env, catalogState ]) => {
        if ( env.hideForIlearn ) {

          // disable catalog as langind page for iLearn24
          catalogState.displayAsHome = false;
        }
        return catalogState;
      }));
  }

  getDetailsUrl(distObject: Distributable, params: string = ''): string | null {

    if ( (distObject?.objType == null) || !(distObject.id > 0) ) {
      return '/home';
    }

    const accountKey = AccountInterceptor.getAccountKey();
    if ( !!accountKey ) {
      params += params.includes('?') ? '&' : '?';
      params += 'key=' + accountKey;
    }

    let catalogUrl = '/catalog';
    if ( this.catalogUUID ) {
      catalogUrl += `/${this.catalogUUID}`;
    }

    switch ( distObject.objType ) {
      case Core.DistributableType.lms_course:
        return `${catalogUrl}/course/${distObject.id}${params}`;
      case Core.DistributableType.lms_curriculum:
        return `${catalogUrl}/curriculum/${distObject.id}${params}`;
      case Core.DistributableType.lms_offlineCnt:
        return `${catalogUrl}/offline/${distObject.id}${params}`;
    }
  }

  purchase(request: Catalogs.PurchaseRequest): Observable<string> {
    const url = ApiUrls.getKey('CatalogPurchase');
    return this.http.post<ApiResponse<any>>(url, JSON.stringify(request), HttpRequestOptions)
      .pipe(map(response => response.url));
  }

  getPurchaseResult(hashId: string, referenceId: string): Observable<Catalogs.PurchaseResult> {
    const url = ApiUrls.getKey('CatalogPurchaseProcess')
      .replace('{hashId}', hashId)
      .replace('{referenceId}', referenceId);
    return this.http.get<Catalogs.PurchaseResult>(url);
  }

  getRedirectByShortUrl(shortUrl: string): Observable<CatalogRedirectResponse> {
    const url = ApiUrls.getKey('GetRedirectByShortUrl')
      .replace(/{shortUrl}/gi, shortUrl);

    return this.http.get<CatalogRedirectResponse>(url);
  }

  handlePurchaseResults(queryParams: Observable<Params>, emptyIfNoResults = true): Observable<Catalogs.PurchaseResult | null> {

    const clearQueryStr = () => {
      const hash = window.location.hash;
      const questMarkPos = hash.indexOf('?');
      if ( questMarkPos > 0 ) {
        this.location.go(hash.substring(1, questMarkPos));
      }
    };

    return queryParams.pipe(switchMap(params => {

      // handle callback from payment gateway
      const hashId = params['hashId'];
      const referenceId = params['referenceId'];
      if ( hashId == null || referenceId == null ) {
        return emptyIfNoResults ? EMPTY : of(null);
      }

      // display message "please wait" and validate the transaction
      this.principalService.fetchUserData(false);
      return this.principalService.isLogged$
        .pipe(switchMap(isLogged => {

          if ( !isLogged ) {
            // user is currently logged out -> force login before continuing the purchase
            const redirect = UrlHelper.getPublicRedirect(window.location.hash.replace(/^#/, ''));
            setTimeout(() => window.location.href = redirect);
            return emptyIfNoResults ? EMPTY : of(null);
          }

          return this.getPurchaseResult(hashId, referenceId)
            .pipe(switchMap(result => {
              clearQueryStr();
              RedirectHelper.clearRedirect();
              if ( result.status === 'processing' ) {
                this.infoService.showMessage(
                  $localize`:@@purchase_processing:The payment has not been instantly completed.`, {
                    infoType: InfoType.Warning,
                  });
                return emptyIfNoResults ? EMPTY : of(null);
              }
              if ( result.status !== 'success' ) {
                this.infoService.showMessage(
                  $localize`:@@purchase_pending:The payment has not been completed.`, {
                    infoType: InfoType.Warning,
                  });
                return emptyIfNoResults ? EMPTY : of(null);
              }
              return of(result);
            }));
        }));
    }));
  }

  findBookingForContentAndUser(userId: number, objType: Core.DistributableType, objId: number ): Observable<Catalogs.CatalogBooking> {
    const url = ApiUrls.getKey('CatalogBookingForContentAndUser')
      .replace('{userId}', String(userId))
      .replace('{objType}', objType)
      .replace('{objId}', String(objId));
    return this.http.get<ApiResponse<Catalogs.CatalogBooking>>(url).pipe(map(response => response.booking));
  }

  addCatalogUUID(
    url: string,
    catalogUUID: string | null,
  ): string {

    this._catalogTitle = null;
    if ( catalogUUID ) {
      catalogUUID = catalogUUID.toLowerCase();
      this._catalogUrl = `/catalog/${catalogUUID}`;
      this.catalogUUID = catalogUUID;
      const separator = url.includes('?') ? '&' : '?';
      return `${url}${separator}catalogUUID=${catalogUUID}`;
    }

    this._catalogUrl = '/catalog';
    this.catalogUUID = null;
    return url;
  }

  private handleError = (): Observable<never> => EMPTY;

  private showDialogRequiredUserFields = (
    userFields: ProfileFieldTypes.ProfileField[],
  ): Observable<void> => {

    if ( !(userFields?.length > 0) ) {
      // no user fields have to be filled out
      return of(void (0));
    }

    return this.infoService.showDialog<CatalogUserFieldsComponent, CatalogUserFieldsData, number>(CatalogUserFieldsComponent, {
      userFields
    }).pipe(map(_ => void(0)));
  };

}
