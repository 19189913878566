import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import {
  GenericMessageDialogComponent,
} from 'src/app/component/generic-message-dialog/generic-message-dialog.component';
import { AnyObject, EMAIL_PATTERN, InputType } from 'src/app/core/core.types';
import { RegistrationFieldInfo, RegistrationInfo } from 'src/app/core/principal/principal.types';
import { State, url as BASE_URL } from '../../../app.state';
import { InfoService } from '../../../core/info/info.service';
import { InfoDialogOptions, InfoType, MessageKey, YesButton, YesNoButtons } from '../../../core/info/info.types';
import { AccountInterceptor } from '../../../core/interceptors/account.interceptor';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { RuntimeEnvironment, RuntimeEnvironmentService } from '../../../core/runtime-environment.service';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { StyleSettings } from '../../admin/account-design/account-design.types';
import { RegisterDataPreviewComponent } from './register-data-preview/register-data-preview.component';
import { OPTIONS_3LC, RegistrationPreview } from './self-register.types';
import { RedirectHelper } from '../../../core/redirect.helper';
import { LoginService } from '../../../core/principal/login.service';
import { RegistrationService } from '../../../core/principal/registration.service';
import { RegisterDataPreviewData } from './register-data-preview/register-data-preview.types';
import { LanguageHelper } from '../../../core/language.helper';


const Errors = {
  passwordNotValid: false,
  emailNotValid: false,
  passwordNotMatch: false,
  emailNotMatch: false,
};

type ERRORS = typeof Errors;

@Component({
  selector: 'rag-self-register',
  templateUrl: './self-register.component.html',
  styleUrls: [ './self-register.component.scss' ],
})
export class SelfRegisterComponent
  implements OnInit, OnDestroy {

  styleSettings$: Observable<StyleSettings>;
  countIt = 0;
  environment: RuntimeEnvironment;
  errors: ERRORS;
  hidePassword = false;
  // maps field type to visual component to be used in the view
  fieldTypeToComponentTypeMapping: AnyObject<string> = {
    text: 'text',
    password: 'text',
    email: 'text',
    dropdown: 'dropdown',
    dateOfBirth: 'date',
    Date: 'date',
    date: 'date',
  };
  formGroup: FormGroup;
  maxBirthDate = moment().subtract(1, 'day').endOf('day');
  passwordValidation: boolean;
  registrationInfo: RegistrationInfo;
  registrationModeFieldsLeft: Array<string> = [];
  registrationModeFieldsRight: Array<string> = [];
  requestIsSent = false;
  requirePassword = true;

  constructor(
    private accountService: AccountDesignService,
    private dateAdapter: DateAdapter<any>,
    private formBuilder: UntypedFormBuilder,
    private infoService: InfoService,
    private loginService: LoginService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
  ) {
    this.registrationInfo = this.route.snapshot.data.info;
    if ( this.registrationInfo == null ) {
      // network error or self registration is not enabled
      return;
    }

    // only require password if the user is not authenticated by sso
    const processState = this.registrationInfo.process?.state ?? {};
    const processChanges = processState.changes ?? {};
    this.requirePassword = !((processState.registrationId != null) && (processState.principalName != null));

    this.runtimeEnvironmentService.environment$
      .pipe(tap(env => this.environment = env))
      .subscribe();

    this.styleSettings$ = this.accountService.getStyleSettings();

    this.dateAdapter.setLocale(State.language);

    this.formGroup = this.formBuilder.group({});

    if ( this.requirePassword ) {
      this.formGroup.addControl('password', new UntypedFormControl(null, [ Validators.required ]));
      this.formGroup.addControl('password2', new UntypedFormControl(null, [ Validators.required ]));
    }

    if ( this.registrationInfo.hasTermsAndConditions ) {
      this.formGroup.addControl('accept', new UntypedFormControl());
    }

    if ( this.registrationInfo && this.registrationInfo.model ) {
      // get all fields in sortorder plus those not listed in but existing in model
      const fieldsIds = [ ...this.registrationInfo.sortorder ];
      Object.keys(this.registrationInfo.model).forEach(fieldId => {
        if ( fieldsIds.includes(fieldId) ) {
          return;
        }
        fieldsIds.push(fieldId);
      });
      // add controlls for each model's field
      fieldsIds.forEach(formFieldId => {
        this.countIt = this.countIt + 1;
        const model = this.registrationInfo.model[formFieldId];
        if ( model && (model.type === 'dropdown') && /3LC/.test(model.label) ) {
          if ( !model.options ) {

            model.options = OPTIONS_3LC
              .map(o => ({ value: o.key, label: LanguageHelper.objectToText(o.value) }));
          }
        }
        // TODO: fixme
        if ( !formFieldId.startsWith('password') && model ) {
          // ignore any password fields in the model
          if ( this.countIt < (fieldsIds.length + 3) / 2 ) {
            this.registrationModeFieldsLeft.push(formFieldId);
          } else {
            this.registrationModeFieldsRight.push(formFieldId);
          }
          let initValue = model.type === 'Date' ? moment() : null;
          let readonly_ = false;
          // handle registration process
          if (this.registrationInfo.process != null && initValue == null && 'email' === formFieldId) {
            switch (this.registrationInfo.process.type) {
              case 'doubleOptIn':
                initValue = processState.email;
                // set the field only to readonly if we know the email address
                readonly_ = !!initValue;
                break;
            }
          }
          if (!initValue && !!processChanges[formFieldId]) {
            // copy any fields from stored changes
            initValue = processChanges[formFieldId];
          }
          // add control to the form
          const formControl = new UntypedFormControl(initValue);
          if (readonly_) {
            formControl.disable();
          }
          this.formGroup.setControl(formFieldId, formControl);
          // @tood: fixme
          if ( 'email' === formFieldId && this.registrationInfo.hasEmailConfirm) {
            const formFieldId2 = `${formFieldId}2`;
            const formControl2 = new UntypedFormControl(initValue);
            if ( formControl.disabled ) {
              formControl2.disable();
            }
            this.formGroup.setControl(formFieldId2, formControl2);
          }
        }
      });
    }

    if ( this.requirePassword ) {
      this.formGroup.controls.password.valueChanges.pipe(map(() => {
        this.validateForm();
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

      this.formGroup.controls.password2.valueChanges.pipe(map(() => {
        this.validateForm();
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
    }
  }

  ngOnInit() {
    this.errors = Errors;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  getControl(controlName: string): FormControl {
    return this.formGroup.get(controlName) as FormControl;
  }

  getTooltip() {
    return $localize`:@@registration_title:Welcome to iLearn24, the learning management system of wingsacademy. You can
    create your personal account by completing and submitting the form below.

    We will then send an email to the address you have provided. Please click on the link in the email to verify your
    email address. Once your address has been verified, you will receive an email confirming that your account on
    iLearn24 has been activated.

    Then you can log in on the start page.`;
  }

  cancel() {
    // ask for confirmation is there is at least one dirty component
    if ( this.formGroup.dirty ) {
      this.infoService
        .showDialog(GenericMessageDialogComponent, {
          message: $localize`:@@validation_leave_without_save_registration:Would you like to leave the page without submitting a registration request?`,
          titleKey: MessageKey.DIALOG.PLEASECONFIRM.TITLE,
          buttons: YesNoButtons,
        })
        .pipe(map(button => {
          if ( button === YesButton ) {
            this.router.navigateByUrl('/home').then();
          }
        }))
        .pipe(take(1))
        .subscribe();
      return;
    }
    // if cancel go to home
    this.router.navigateByUrl('/home').then();
    // this.navigationService.navigateBack();
  }

  errorForField(controlName: string, errorCode: string): string {
    return this.formGroup.controls[controlName].getError(errorCode);
  }

  fieldInputTypeForField(fieldInfo: RegistrationFieldInfo): InputType {
    switch ( fieldInfo.field ) {
      case 'email':
        return 'email';
      case 'password':
      case 'password1':
      case 'password2':
        return 'password';
      default:
        return 'text';
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    const control = this.formGroup.get(controlName);
    return control?.hasError(errorName);
  }

  navigateBackToLogin() {
    this.router.navigateByUrl('/login').then();
  }

  onPasswordValidationChange($event: boolean) {
    this.passwordValidation = $event;
    this.validateForm();
  }

  onTextFieldChange($event: any) {
    // TODO: what is the next line doing?
    // if ( !this.registrationInfo.usernameEqualsField || 'false' === this.registrationInfo.usernameEqualsField ) {
    //   return;
    // }
    if ( this.formGroup.controls.email?.value == null || this.formGroup.controls.email.value === '' ) {
      this.errors.emailNotValid = false;
    } else if (this.formGroup.controls.email2 != null) {
      const regExp = EMAIL_PATTERN.test(this.formGroup.controls.email.value);
      this.errors.emailNotValid = !regExp;

      if ( this.errors.emailNotValid ) {
        this.formGroup.controls.email.setErrors(this.errors);
      } else {
        this.formGroup.controls.email.setErrors(null);
      }
      if ( this.formGroup.controls.email.value !== this.formGroup.controls.email2.value ) {
        if ( this.formGroup.controls.email2.value === '' || this.formGroup.controls.email2.value == null ) {
          this.errors.emailNotMatch = false;
        } else {
          this.errors.emailNotMatch = true;
          this.formGroup.controls.email2.setErrors(this.errors);
        }
      } else {
        this.formGroup.controls.email2.setErrors(null);
      }
    }
    const target = $event.target;
    if ( target.name === 'username' ) {
      return;
    }
    if ( target.name === this.registrationInfo.usernameEqualsField ) {
      const userNameComponent = this.formGroup.get('username');
      if ( userNameComponent != null ) {
        userNameComponent.setValue(this.formGroup.value[this.registrationInfo.usernameEqualsField]);
      }
    }
  }

  tryRegister() {


    if ( this.requirePassword ) {
      this.formGroup.controls.password.markAsDirty();
    }

    if ( !this.registrationInfo || !this.registrationInfo.model || !this.registrationInfo.sortorder ) {
      return;
    }

    if ( this.formGroup.pristine || this.formGroup.invalid ) {
      return;
    }

    const allFields = [
      ...this.registrationModeFieldsLeft,
      ...this.registrationModeFieldsRight].reduce<RegistrationPreview>((result, fieldId) => {

      const model = this.registrationInfo.model[fieldId];
      if ( !model ) {
        return result;
      }
      // TODO:fixme
      let value = this.formGroup.value[fieldId];
      if ( value == null ) {
        // the server provides wrong sortorder collection. Very common to have a field in sort
        // order without corresponding entry in the model.
        return result;
      }

      if ( model.type === 'date' || model.type === 'Date' ) {

        // format date as string
        value = value.format('DD.MM.YYYY');

      } else if (typeof(value) === 'string') {

        // remove preceding, and trailing white space
        value = value.trim();

        if (!value) {
          // skip empty values
          return result;
        }
      }

      //
      result[fieldId] = {
        title: model.label,
        value,
      };

      return result;
    }, {});

    let login: string = this.registrationInfo.login || null;
    let password: string;

    this.infoService
      .showDialog<RegisterDataPreviewComponent, RegisterDataPreviewData>(RegisterDataPreviewComponent, {
        data: allFields,
        model: this.registrationInfo.model,
      })
      .pipe(map(confirmed => {
        if ( !confirmed ) {
          return null;
        }

        const request = Object.entries(this.formGroup.value)
          .reduce((result, [ key, value ]) => {
            result[key] = value || '';
            return result;
          }, {} as any);
        const accountKey = AccountInterceptor.getAccountKey();
        request.url = BASE_URL + window.location.pathname + '?key=' + accountKey + '#/register';
        delete request.password2;

        if (this.registrationInfo.process != null) {
          const email = this.formGroup.controls.email?.value;
          if ( email != null ) {
            request['email'] = email.trim();
          }
          request['processId'] = this.registrationInfo.process.hash;
        }

        if ( !request['username'] ) {
          // todo train api names this field as "true" if not required -.-
          // todo if username is required, our code does not render an input for it
          request['true'] = request['username'] = login || request.email;
        }

        // TODO:fixme
        // fix the date fields
        this.registrationModeFieldsLeft.forEach(fieldId => {
          const model = this.registrationInfo.model[fieldId];
          if ( model.type === 'date' || model.type === 'Date' ) {
            const value = request[fieldId];
            if ( value != null && moment.isMoment(value) ) {
              if ( value.isValid() ) {
                request[fieldId] = value.format('DD.MM.YYYY');
              }
            }
          }
        });

        // copy sso values to registration request -> should include login + password
        const ssoChanges = this.registrationInfo.process?.state?.changes ?? {};
        Object.entries(ssoChanges)
          .map(([ key, value ]) => {
            switch ( key ) {
              case 'pass':
                request['password'] = value;
                break;
              case 'login':
                request['username'] = value;
                break;
              default:
                request[key] = value;
            }
          });

        login = request['username'];
        password = request['password'];

        return request;
      }))
      .pipe(switchMap(request => {
        if ( request ) {
          return this.registrationService.registerUser(request)
            .pipe(catchError(e => {
              return of(false);
            }));
        }
        return of(false);
      }))
      .pipe(switchMap(done => {
        const process = this.registrationInfo.process;
        if (done && process?.type === 'doubleOptIn') {

          // store process as post-login redirect
          return RedirectHelper.setRedirect('/process/' + process.hash)

            // wait 2 seconds until the registration in train completes (hopefully)
            .pipe(delay(2000))

            // do a login (with the new credentials)
            .pipe(switchMap(_ => this.loginService.login(null, login, password)))

            // .pipe(tap(() => window.location.reload()))
            .pipe(map(_ => true));
        }
        return of(done);
      }))
      .pipe(tap(done => this.requestIsSent = done))
      .pipe(catchError(error => {
        const trainError = error.error == null ? error : error.error;
        if ( trainError.username != null ) {
          // move username errors to email field
          trainError.email = trainError.username;
          delete trainError.username;
        }
        // server side validation has reported some errors.
        Object.keys(trainError).forEach(failedFieldId => {
          const controll = this.formGroup.controls[failedFieldId];
          if ( controll ) {
            controll.setErrors({ server: trainError[failedFieldId] });
          }
        });

        const errorCode = error.errorCode ?? trainError.errorCode;
        if ( errorCode === '#SRS1' ) {
          this.infoService.showMessage($localize`:@@self_register_username_already_taken:
            (Error: #SRS1) Registration is not possible with this e-mail address. Please contact Support.`, {
            durationInSeconds: 30,
            infoType: InfoType.Error,
          });
        }
        return error;
      }))
      .subscribe();
  }

  private validateForm() {
    const password = this.formGroup.controls.password.value;
    const password2 = this.formGroup.controls.password2.value;
    if ( password !== password2 ) {
      this.errors.passwordNotValid = true;
      this.errors.passwordNotMatch = true;
      setTimeout(() => {
        this.formGroup.controls.password2.setErrors(this.errors);
        this.formGroup.controls.password.setErrors(this.errors);
      });
      return;
    }
    if ( this.formGroup.controls.password.value === '' ) {
      this.formGroup.controls.password.setErrors({ required: true });
      this.formGroup.controls.password2.setErrors({ required: true });
      return;
    }
    if ( !this.passwordValidation ) {
      this.errors.passwordNotValid = true;
      this.errors.passwordNotMatch = false;
      setTimeout(() => {
        this.formGroup.controls.password2.setErrors(this.errors);
        this.formGroup.controls.password.setErrors(this.errors);
      });
      return;
    }
    this.errors.passwordNotMatch = false;
    this.errors.passwordNotValid = false;
    setTimeout(() => {
      this.formGroup.controls.password2.setErrors(null);
      this.formGroup.controls.password.setErrors(null);
    });
  }

}
